@font-face {
  font-family: "brandish";
  src: url("./assets/fonts/brandishregular.ttf");
}

p {
  font-size: 16px !important;
}

html {
  scroll-behavior: smooth;
}

.brandish-font {
  letter-spacing: 0.1rem;
}
.spin {
  animation-name: spin;
  animation-duration: 50000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes spin {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@media (max-width: 991px) {
  .service_section_css_style {
    margin-top: 2rem;
  }
}
@media (max-width: 500px) {
  .codeAnimation {
    left: 80px !important;
  }
  .spin {
    top: 16% !important;
  }
}
.image-1 {
  right: -100px;
}
.image-2 {
  left: -100px;
}
.image-3 {
  left: -100px;
}
@media (max-width: 991px) {
  .service_section_css_style {
    height: 570px;
  }
}
@media (max-width: 767px) {
  .service_section_css_style {
    height: 400px;
  }
}
@media (max-width: 500px) {
  .service_section_css_style {
    height: 300px;
  }
}
.social_media_chart {
  left: 56px !important;
  top: 40px !important;
}
@media (max-width: 991px) and (min-width: 767px) {
  .social_media_chart {
    top: 115px !important;
    left: 100px !important;
  }
}
@media (max-width: 700px) {
  .video_spin {
    width: 50px !important;
    height: 50px !important;
    top: 35px !important;
    left: 150px !important;
  }
}
.R-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  writing-mode: vertical-lr;
  width: 100px;
  height: 100px;
  border: solid red 1px;
  transform-style: preserve-3D;
  transition: all 1s ease-in-out;
  transform-origin: top left;
}
.rotate {
  transition: all 1s ease-in-out;
}
.R-container:hover {
  transform: rotate(360deg);
}
.brand_rotation_con {
  transform: rotate(-20deg);
  left: 50px;
  top: 15%;
  position: absolute;
}

@media (max-width: 991px) {
  .brand_rotation_con {
    left: 29%;
    top: 29%;
  }
}

.brand_rotation {
  transform-style: preserve-3D;
  transform-origin: bottom left;
  transform: rotate(21deg);
}
.brand_rotation.num_1 {
  transform-style: preserve-3D;
  transform-origin: bottom left;
  transform: rotate(69deg);
}
.brand_rotation.num_2 {
  transform-style: preserve-3D;
  transform-origin: bottom left;
  transform: rotate(111deg);
}
.vertical-timeline-element-icon {
  box-shadow: 0 0 0 4px #97a7b5, inset 0 2px 0 rgba(0, 0, 0, 0.08),
    0 3px 0 4px rgba(0, 0, 0, 0.05) !important;
}
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: white !important;
}

.react-freezeframe {
  width: 50px;
  height: 30px;
  margin: auto;
}
.vertical-timeline::before {
  height: 99% !important;
  top: 28px !important;
}

.newP {
  font-size: 11px !important;
  margin: 5px 4px !important;
  background-color: transparent;
  color: #777;
  border: 2px solid #777;
  border-radius: 4px;
  padding: 2px 7px;
  font-weight: 500 !important;
}

.vertical-timeline-element-icon {
  box-shadow: 0 0 0 4px #777, inset 0 2px 0 rgb(0 0 0 / 8%),
    0 3px 0 4px rgb(0 0 0 / 5%) !important;
}

.vertical-timeline::before {
  background: #777 !important;
}

.liveRouteSection .container {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.liveRouteSection .container > img {
  margin: 0rem auto 3rem;
  width: 300px;
}

.liveRouteSection .container .appImages {
  display: flex;
  align-content: center;
  justify-content: space-between;
}

.liveRouteSection .container .appImages img {
  width: 250px;
  border-radius: 10px;
}

@media (max-width: 1199px) {
  .liveRouteSection .container .appImages img {
    width: 220px;
  }
}

@media (max-width: 991px) {
  .liveRouteSection .container .appImages img {
    width: 160px;
  }
  .liveRouteSection .container > img {
    width: 200px;
  }
}

@media (max-width: 767px) {
  .liveRouteSection .container .appImages img {
    width: 120px;
  }
}

@media (max-width: 553px) {
  .liveRouteSection .container .appImages {
    flex-direction: column;
    justify-content: center !important;
  }
  .liveRouteSection .container .appImages img {
    width: 90%;
    margin: 0.5rem auto;
  }
  .liveRouteSection .container p {
    padding: 1rem !important;
  }
}

.liveRouteSection .container p {
  font-size: 25px !important;
  text-align: center;
  padding: 2rem;
}

.liveRouteSection .container button {
  width: 150px;
  padding: 0.5rem;
  outline: none;
  margin: auto;
  border: none;
}

.liveRouteSection .container a {
  margin: auto;
}

/* .liveRouteSection {
  background-color: #f9716a23;
  padding-top: 1rem;
  padding-bottom: 2rem;
} */
